import {useGlobalNavigationQuery} from './__generated__/GlobalNavigation.graphql';
import GenericFooter from './Footer/GenericFooter';
import GlobalNavigationLayout, {
  Props as GlobalNavigationLayoutProps,
} from './GlobalNavigationLayout';
import {ViewerIDContext} from './hooks/useViewerID';
import GenericNavControls from './Nav/GenericNavControls';

import React from 'react';

type OmitKeys = 'extraNav' | 'footer' | 'user' | 'featureFlags';
export interface Props extends Omit<GlobalNavigationLayoutProps, OmitKeys> {}

export default function DefaultPageLayout(props: Props): JSX.Element {
  const {children, ...others} = props;
  const {data} = useGlobalNavigationQuery();

  return (
    <ViewerIDContext.Provider value={{id: data?.viewer?.id}}>
      <GlobalNavigationLayout
        extraNav={GenericNavControls}
        featureFlags={data?.featureFlags}
        footer={<GenericFooter />}
        user={data?.viewer}
        {...others}
      >
        {children}
      </GlobalNavigationLayout>
    </ViewerIDContext.Provider>
  );
}

import MarketingTopNavItem, {Placement} from './MarketingTopNavItem';
import {Options as NavLinkProperty} from './useNavLinkProperty';
import brand from '../../../../__generated__/intl/strings/brand';
import events from '../../../../__generated__/intl/strings/nav/events';
import about from '../../../../__generated__/intl/strings/pages/about/about';
import ourCommunity from '../../../../__generated__/intl/strings/pages/community/ourCommunity';
import faq from '../../../../__generated__/intl/strings/pages/faq/faq';
import {linkToMarketing} from '../../../url/linkToMarketing';
import {ContentColor} from '../../design_system/theme/useContentPalette';

import React from 'react';

export interface Options {
  readonly navColor?: NavLinkProperty<ContentColor>;
  readonly placement?: Placement;
}

export function getGenericNavControls(options: Options = {}): JSX.Element[] {
  const {navColor, placement = 'header'} = options;
  return [
    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="about"
      matchColor={navColor?.matchedValue}
      placement={placement}
      to={linkToMarketing('/about')}
    >
      {about(brand())}
    </MarketingTopNavItem>,

    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="event"
      matchColor={navColor?.matchedValue}
      matcher="/event/:id?"
      placement={placement}
      to={linkToMarketing('/event')}
    >
      {events()}
    </MarketingTopNavItem>,

    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="community"
      matchColor={navColor?.matchedValue}
      placement={placement}
      to={linkToMarketing('/community')}
    >
      {ourCommunity()}
    </MarketingTopNavItem>,

    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="faq"
      matchColor={navColor?.matchedValue}
      placement={placement}
      to={linkToMarketing('/faq')}
    >
      {faq()}
    </MarketingTopNavItem>,
  ];
}

const GenericNavControls: JSX.Element[] = getGenericNavControls();

export default GenericNavControls;

import InfoFooter from './InfoFooter';
import PolicyFooter from './PolicyFooter';

import React from 'react';

export default function GenericFooter(): JSX.Element {
  return (
    <div>
      <InfoFooter />
      <PolicyFooter />
    </div>
  );
}

import {useMatch} from 'react-router-dom';

export interface Options<T> {
  readonly idleValue: T;
  readonly matchedValue: T;
}

export default function useNavLinkProperty<T>(
  pathPattern: string,
  options: Options<T>,
): T {
  const {idleValue, matchedValue} = options;
  const match = useMatch(pathPattern);
  return match == null ? idleValue : matchedValue;
}

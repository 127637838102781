import NavLink, {Props as NavLinkProps} from './NavLink';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import useMediaQueryGetter, {
  MediaQuery,
} from '../../design_system/hooks/useMediaQueryGetter';
import ChevronRight from '../../design_system/icon/ChevronRight';
import {ContentColor} from '../../design_system/theme/useContentPalette';
import Gap from '../flex/Gap';

import {css} from '@emotion/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';

export type Placement = 'header' | 'footer';

export interface Props extends NavLinkProps {
  readonly placement?: Placement;
}

export default function MarketingTopnavItem(props: Props): JSX.Element {
  const {placement = 'header', to} = props;
  const {palettes, spacing} = useAppTheme();
  const navigate = useNavigate();
  const mq = useMediaQueryGetter();

  if (placement === 'footer') {
    return <NavLink {...props} />;
  }

  return (
    <>
      {/* Desktop version */}
      <NavLink
        sx={css({
          [mq(MediaQuery.SmallAndMedium)]: {
            display: 'none',
          },
        })}
        {...props}
      />

      {/* Mobile version */}
      <div
        css={css({
          display: 'flex',
          alignItems: 'center',
          borderBottom: `2px solid ${palettes.background.neutral.disabled}`,
          marginLeft: spacing.x8,
          marginRight: spacing.x8,
          paddingBottom: spacing.x8,
          paddingTop: spacing.x8,
          width: '100%',

          [mq(MediaQuery.LargeAndExtraLarge)]: {
            display: 'none',
          },
        })}
        role="link"
        onClick={() => {
          navigate(to);
        }}
      >
        <NavLink {...props} />
        <Gap />
        <ChevronRight
          color={ContentColor.PRIMARY_SUBDUED}
          height={24}
          width={12}
        />
      </div>
    </>
  );
}

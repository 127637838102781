import useNavLinkProperty from './useNavLinkProperty';
import {CSSStyles} from '../../../types/CSSStyles';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import TextLink from '../../design_system/Link/TextLink';
import {ContentColor} from '../../design_system/theme/useContentPalette';
import Flex from '../flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly children: string;
  readonly to: string;
  readonly idleColor?: ContentColor;
  readonly idleIcon?: React.ReactNode;
  readonly matchColor?: ContentColor;
  readonly matcher?: string;
  readonly matchIcon?: React.ReactNode;
  readonly sx?: CSSStyles;
}

export default function NavLink(props: Props): JSX.Element {
  const {
    children,
    idleColor,
    idleIcon,
    matchColor,
    matcher,
    matchIcon,
    sx,
    to,
  } = props;
  const {spacing} = useAppTheme();
  const icon = useNavLinkProperty(matcher ?? to, {
    idleValue: idleIcon,
    matchedValue: matchIcon,
  });
  return (
    <Flex
      sx={[
        css({
          gap: spacing.x12,
        }),
        sx,
      ]}
    >
      {icon}
      <TextLink
        textProps={{
          color: useNavLinkProperty(matcher ?? to, {
            idleValue: idleColor ?? ContentColor.PRIMARY,
            matchedValue: matchColor ?? ContentColor.SECONDARY,
          }),
          sx: css({whiteSpace: 'nowrap'}),
        }}
        to={to}
      >
        {children}
      </TextLink>
    </Flex>
  );
}

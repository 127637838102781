import isNonEmptyString from '../isNonEmptyString';

export default function appendNonEmptySearchParams(
  baseUrl: string,
  params: Record<string, Maybe<string>>,
): string {
  const searchParams = new URLSearchParams();
  Object.entries(params).map(([key, value]) => {
    if (isNonEmptyString(value)) {
      searchParams.append(key, value);
    }
  });

  const searchString = searchParams.toString();
  if (isNonEmptyString(searchString)) {
    return baseUrl + '?' + searchString;
  }
  return baseUrl;
}

import {AppIconProps} from '../../../design_system/icon/AppIconProps';
import useFeatherIconProps from '../../../design_system/icon/useFeatherIconProps';

import React from 'react';

export default function FooterTwitterIcon(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <rect
        fill="#6A4956"
        height="40"
        rx="20"
        width="40"
      />
      <g clipPath="url(#clip0_1688_6175)">
        <path
          d="M21.6408 18.4643L28.9224 10H27.1969L20.8743 17.3494L15.8244 10H10L17.6364 21.1136L10 29.9897H11.7256L18.4025 22.2285L23.7355 29.9897H29.5599L21.6404 18.4643H21.6408ZM19.2773 21.2115L18.5036 20.1049L12.3474 11.299H14.9978L19.966 18.4056L20.7397 19.5123L27.1977 28.7498H24.5473L19.2773 21.212V21.2115Z"
          fill="#C7BBC0"
          stroke="#C7BBC0"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_6175">
          <rect
            fill="white"
            height="20"
            transform="translate(10 10)"
            width="19.5599"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import {CSSStyles} from '../../../types/CSSStyles';
import React from 'react';

export interface Props {
  readonly style?: CSSStyles;
}

export default function StartPattern(props: Props): JSX.Element {
  const {style} = props;
  return (
    <div css={style}>
      <svg
        width="407"
        height="195"
        viewBox="0 0 407 195"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1028_444)">
          <path
            d="M-72.5367 194.977C-35.0114 189.906 -4.13909 165.919 25.6976 144.501C40.8081 133.655 56.1776 122.927 72.9828 114.84C81.1892 110.89 89.7409 107.63 98.5907 105.42C103.133 104.284 107.762 103.375 112.422 102.889C117.083 102.403 123.351 102.294 126.419 106.792C129.008 110.585 128.192 115.772 126.741 119.824C124.991 124.69 121.602 129.463 117.271 132.355C112.94 135.246 107.613 135.599 103.526 132.08C99.438 128.562 98.0337 123.499 98.5201 118.484C99.6106 107.317 108.853 97.2859 117.004 90.3192C125.156 83.3525 134.704 78.0942 144.668 74.2465C168.22 65.1482 193.742 64.0197 218.659 62.4759C243.639 60.9321 268.643 58.4244 292.996 52.4295C334.977 42.093 376.104 20.0801 399.531 -17.4727C402.245 -21.822 404.662 -26.3515 406.796 -31.0143C407.839 -33.2947 404.458 -35.2852 403.407 -32.9891C383.879 9.78289 341.042 35.5652 297.036 47.3435C271.585 54.1535 245.342 56.9041 219.114 58.5263C193.349 60.125 166.839 61.2378 142.557 70.8767C132.319 74.9439 122.614 80.3668 114.219 87.5451C105.879 94.6764 97.202 104.488 95.0053 115.561C93.1694 124.855 97.5002 136.633 107.927 138.404C118.354 140.175 126.905 130.215 130.271 121.477C133.637 112.74 131.754 101.165 120.864 99.1197C115.969 98.2028 110.877 99.0335 106.028 99.8485C101.18 100.663 96.7941 101.721 92.2829 103.069C73.7595 108.602 56.8366 118.382 40.8787 129.095C8.82961 150.622 -21.5013 178.316 -59.67 188.417C-64.2518 189.632 -68.9042 190.549 -73.6037 191.184C-76.0908 191.521 -75.0316 195.298 -72.5602 194.961L-72.5367 194.977Z"
            fill="url(#paint0_linear_1028_444)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1028_444"
            x1="-66.2267"
            y1="76.3085"
            x2="392.04"
            y2="76.3085"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF3A8B" />
            <stop
              offset="1"
              stopColor="#FFBA4A"
            />
          </linearGradient>
          <clipPath id="clip0_1028_444">
            <rect
              width="482"
              height="229"
              fill="white"
              transform="translate(-75 -34)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
